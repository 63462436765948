import React, { ChangeEvent, useState } from "react";
import { Button, IconButton, InputAdornment, Pagination } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import useUsers from "../../containers/Users/useUsers";
import Loader from "../Loader/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  BtnAccept,
  Container,
  ContainerBtns,
  Title,
} from "../NFTRequestsChild/nftRequestsStyles";
import { ContainerPagination, SpanCopy } from "./userStyles";
import { profile } from "../../services/api";
import {
  BtnDisabled,
  SubTitle,
} from "../../containers/NFTRequests/requestsStyles";
import SearchIcon from "@mui/icons-material/Search";
import { Input } from "../../containers/Auditors/auditorsStyle";
import { IUsers } from "../../containers/Users/types";

const countryName = (value: any) => {
  if (typeof value == "string") {
    switch (value.toLowerCase()) {
      case "ar":
        return "🇦🇷 Argentina";
      case "mx":
        return "🇲🇽 Mexico";
      case "cl":
        return "🇨🇱 Chile";
      case "uy":
        return "🇺🇾 Uruguay";
      case "py":
        return "🇵🇾 Paraguay";
    }
  }

  return value;
};

const UsersMap = () => {
  const {
    users,
    approveUser,
    page,
    setPage,
    pageCount,
    loading,
    buttonLoading,
    getUsers,
    search,
    setSearch,
    getBalance,
  } = useUsers();

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event: ChangeEvent<unknown>, _page: number) => {
    setPage(_page);
  };

  const handleKeyDown = async (event: any) => {
    if (event.key === "Enter") {
      await getUsers();
    }
  };

  const handleCopy = () => {
    alert("Copiado al portapapeles.");
  };

  const getUserBalance = (user: IUsers) => {
    getBalance(user).then((res) => {
      if (res.tcoe && res.dai) {
        alert(`El balance del usuario es: ${res.tcoe} TCOE y ${res.dai} DAI`);
      }
    });
  };

  const getDocName = (doctype: string): string => {
    switch (doctype) {
      case "DNI_FRONT":
      case "NATIONAL_DOCUMENT_FRONT":
        return "DNI Frente";
      case "DNI_BACK":
      case "NATIONAL_DOCUMENT_BACK":
        return "DNI Dorso";
      case "COMPANY_TAX_CERTIFICATE":
        return "Constancia Fiscal";
      case "COMPANY_AUTHORIZATION":
        return "Autorización";
      default:
        return doctype;
    }
  };
  const exportCsv = () => {
    setIsLoading(true);
    profile.downloadCsv().finally(() => {
      alert("Usuarios descargados.");
      setIsLoading(false);
    });
  };

  return (
    <Container>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Title>Usuarios</Title>
        {isLoading ? (
          <BtnDisabled style={{ height: "fit-content" }}>
            Exportar CSV
          </BtnDisabled>
        ) : (
          <BtnAccept style={{ height: "fit-content" }} onClick={exportCsv}>
            Exportar CSV
          </BtnAccept>
        )}
      </div>
      <FormControl
        variant="standard"
        sx={{ m: 1, minWidth: 120, display: "flex" }}
      >
        <TextField
          fullWidth={true}
          label="Buscar por correo, dni o address"
          variant="standard"
          value={search}
          onChange={(e: any) => setSearch(e.target.value)}
          onKeyDown={handleKeyDown}
          inputProps={{ style: { color: "#fff" } }}
          sx={{
            marginBottom: "20px",
            width: "100%",
            color: "white",
            label: {
              color: "white",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon style={{ color: "white" }} onClick={getUsers} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      {loading ? (
        <Loader />
      ) : (
        <TableContainer
          sx={{
            backgroundColor: "transparent",
          }}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: "white" }}>
                  Nombre / Email / Nro de Tel
                </TableCell>
                <TableCell sx={{ color: "white" }}>País</TableCell>
                <TableCell sx={{ color: "white" }}>DNI</TableCell>
                <TableCell sx={{ color: "white" }}>CUIT/RUT/RFC/RUC</TableCell>
                <TableCell sx={{ color: "white" }}>Address</TableCell>
                <TableCell sx={{ color: "white" }}>Documentación</TableCell>
                <TableCell sx={{ color: "white" }}>Estado</TableCell>
                <TableCell sx={{ color: "white" }}>Balance</TableCell>
                <TableCell sx={{ color: "white" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.length
                ? users.map((user: any) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell sx={{ color: "white" }}>
                        <div>
                          <strong>
                            {user.name} {user.surname}
                          </strong>
                        </div>
                        {user.company_name ? (
                          <div>
                            <strong>{user.company_name}</strong>
                          </div>
                        ) : null}
                        <div>{user.email}</div>
                        <div>{user.phone_number}</div>
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {countryName(user.country)}
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>{user.dni}</TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {user.company_identification_number ?? ""}
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {user?.address.slice(0, 5)}...
                        <CopyToClipboard
                          text={user ? user.address : null}
                          onCopy={() => handleCopy()}
                        >
                          <SpanCopy>
                            <ContentCopyIcon
                              style={{
                                width: "15px",
                              }}
                            />
                          </SpanCopy>
                        </CopyToClipboard>
                      </TableCell>

                      <TableCell sx={{ color: "white" }}>
                        {user.documents?.map((doc: any) => {
                          return (
                            <BtnAccept
                              key={`user_doc_${doc._id}`}
                              disabled={buttonLoading}
                              onClick={() => {
                                window.open(doc.url);
                              }}
                            >
                              {getDocName(doc.doctype)}
                            </BtnAccept>
                          );
                        })}
                      </TableCell>

                      <TableCell sx={{ color: "white" }}>
                        {user.validated ? "Validado" : "No validado"}
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {!user.validated ? (
                          <ContainerBtns>
                            <BtnAccept
                              disabled={buttonLoading}
                              onClick={() => approveUser(user)}
                            >
                              Validar
                            </BtnAccept>
                          </ContainerBtns>
                        ) : null}
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        <Button onClick={() => getUserBalance(user)}>
                          Balance
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
          {!users.length ? <SubTitle>No hay usuarios</SubTitle> : null}
        </TableContainer>
      )}
      <ContainerPagination>
        <Pagination
          count={pageCount}
          page={page}
          onChange={handleChange}
          color="primary"
          variant="outlined"
          shape="rounded"
          sx={{
            button: {
              color: "white",
            },
          }}
        />
      </ContainerPagination>
    </Container>
  );
};

export default UsersMap;
